/*
 * @Author: wengold
 * @Date: 2023-09-01 10:40:23
 * @LastEditors: hucongcong congcong.hu@wengold.net
 * @LastEditTime: 2024-01-17 08:18:37
 * @Description: 
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import zhCnTrans from "./zh.json";
import enTrans from "./en.json";


let language = navigator.language;

i18n
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    lng: language,
    resources: {
      zh: {
        translation: zhCnTrans
      },
      en: {
        translation: enTrans
      },
    }
  });

// 切换语言事件
// onClick={()=>i18n.changeLanguage(i18n.language=='en'?'zh':'en')}

export default i18n;